@tailwind base;
@tailwind components;
@tailwind utilities;

.collective {
    @apply flex flex-col justify-center items-center;
}
.fullscreen.collective {
    @apply text-sm h-fit top-0 pt-12;
    /* @apply p-0 fixed overflow-scroll; */
}
.collective .artist {@apply mb-6;}
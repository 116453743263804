@tailwind base;
@tailwind components;
@tailwind utilities;

.artist {
  @apply grid text-left gap-8;
  grid-template-columns: 1fr 2fr;
}
.end-frame:after {
  @apply absolute bg-dark h-screen w-screen left-0 top-0 z-0;
  content: '';
}
.end-frame-inner {@apply relative z-30;}
  .end-frame h1 { @apply sticky w-full mx-auto top-0 bg-gradient-to-b from-dark via-dark; } 
  .end-frame h1 img { @apply mx-auto; }
  .end-frame h1:after {
    @apply block bg-no-repeat bg-arrow bg-auto w-full h-16 bg-center mt-8 sm:hidden;
    transition: opacity 1s;
    content: '';
  }
  .end-frame h1.scrolled:after {
    opacity: 0;
  }

.artist:nth-child(3) {@apply hidden;}

.artist img {
 @apply w-full object-cover;
}

.fullscreen.end-frame {
  @apply h-full overflow-visible overflow-x-hidden;
}

.end-frame-inner {
 @apply absolute top-[35%] bg-dark pb-12;   
}
@media all and (min-width: 640px) {
  .artist {
    grid-template-columns: 1fr 4fr;
  }
}
@media all and (min-width: 1024px) {
  .artist {
    grid-template-columns: 1fr 2fr;
  }
}

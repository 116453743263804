@tailwind base;
@tailwind components;
@tailwind utilities;
@import './style/player.css';
@import './style/endframe.css';
@import './style/credits.css';
@import './style/share.css';

.App {
  @apply text-light text-center bg-dark h-screen w-screen flex items-center justify-center portrait:flex-col;
}
nav {
  @apply hidden;
  transition: all .5s ease;
}
nav svg {@apply text-lg;}

.end-frame,
.collective {
  @apply absolute h-full w-full bg-dark text-light z-10;
}
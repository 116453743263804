@tailwind base;
@tailwind components;
@tailwind utilities;

.video-player {
    @apply cursor-pointer aspect-4/3 object-cover max-h-screen mx-auto;
    transition: opacity .5s ease;
}

.video-controls {
    @apply flex z-[1] text-light absolute bottom-0 items-baseline w-full justify-start;
    transition: opacity 1s;
}

.video-overlay {
    @apply absolute h-screen w-screen bg-[rgba(0,0,0,0.8)] z-50 p-4;
}

.video-overlay dialog { @apply w-full mx-auto block bg-[transparent]; }

.player-wrap {
    @apply relative basis-full portrait:overflow-hidden;
    transition: all .5s ease;
}
.player-wrap.waiting { @apply pointer-events-none cursor-default; }
.player-wrap.half {
    @apply basis-1/2;
}
.player-wrap.half:first-child .video-controls { opacity: 0; }
.player-wrap.buffering {
    @apply h-[5px] absolute bottom-0 w-full overflow-hidden;
}
.player-wrap.buffered  .video-player{
    opacity: .3;
}

.video-wrap {
    @apply flex flex-row portrait:flex-col;
}
.video-wrap:has(.buffering) {
    @apply flex-col relative; 
}
.player-wrap:has() {
    background:rgba(0,0,0,0.5);
}
/* .video-wrap:has(div.overlay) > .player-wrap{
    @apply relative;
} */
.video-wrap:has(div.overlay) > .player-wrap:after {
    @apply absolute h-full w-full left-0 top-0 bg-[rgba(0,0,0,0.5)];
    content: '';
    transition: all .5s ease;
}

  
.rupture-icon, .trigger-warning {
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.rupture-icon, .trigger-warning {
    @apply absolute top-1/2 left-1/2 w-full h-1/3 z-30 pointer-events-none;
}

.rupture-icon.in path {
    stroke-dasharray: 300;
    stroke-dashoffset: 0;
    animation: dashin 2s linear;
}
.rupture-icon.out path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: dashout 2s linear;
}
@keyframes dashin {
    from {
      stroke-dashoffset: 300;
    }
    to {
      stroke-dashoffset: 0;
    }
}
@keyframes dashout {
    from {
      stroke-dashoffset: 600;
    }
    to {
      stroke-dashoffset: 300;
    }
}

.rupture-icon.in path#xl {
    stroke-dasharray: 2400;
    stroke-dashoffset: 0;
    animation: dashxlin 2s linear;
}
.rupture-icon.out path#xl {
    stroke-dasharray: 2400;
    stroke-dashoffset: 2400;
    animation: dashxlout 2s linear;
}
@keyframes dashxlin {
    from {
      stroke-dashoffset: 2400;
    }
    to {
      stroke-dashoffset: 0;
    }
}
@keyframes dashxlout {
    from {
      stroke-dashoffset: 4800;
    }
    to {
      stroke-dashoffset: 2400;
    }
}


.rupture-icon.in path#lg {
    stroke-dasharray: 550;
    stroke-dashoffset: 0;
    animation: dashlgin 2s linear;
}
.rupture-icon.out path#lg {
    stroke-dasharray: 550;
    stroke-dashoffset: 550;
    animation: dashlgout 2s linear;
}
.rupture-icon.in path#diamond {
    stroke-dasharray: 550;
    stroke-dashoffset: 0;
    animation: dashlgin 2s linear;
}
.rupture-icon.out path#diamond {
    stroke-dasharray: 550;
    stroke-dashoffset: 550;
    animation: dashlgout 2s linear;
}
@keyframes dashlgin {
    from {
      stroke-dashoffset: 550;
    }
    to {
      stroke-dashoffset: 0;
    }
}
@keyframes dashlgout {
    from {
      stroke-dashoffset: 1100;
    }
    to {
      stroke-dashoffset: 550;
    }
}

.rupture-icon.in path#autolysis {
    animation: dashin 2s linear 2s both;
}

input[type="range"] {
    @apply pointer-events-none;
}

.player-wrap.debug input[type="range"] {
    @apply pointer-events-auto;
}

input[type="range"]::-moz-range-progress,
input[type="range"]::-ms-fill-lower {
    @apply bg-[transparent]; 
}
input[type="range"]::-moz-range-track,
input[type="range"]::-ms-fill-upper  {  
    background-color: transparent;
}
 
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        width: 100%;
        -webkit-appearance: none;
        background-color: transparent;
        height: 5px;
    }
    input[type='range']::-webkit-slider-runnable-track {
        height: .15em;
        -webkit-appearance: none;
        color: white;
        margin-top: -1px;
    }
    input[type='range']::-webkit-slider-thumb {
        width: 0px;
        -webkit-appearance: none;
        height: 5px;
        cursor: ew-resize;
        background: transparent;
        position: relative;
        box-shadow: -100vw 0 0 100vw white;

    }
} /* end media */ 

.bufferBar {
    @apply absolute top-0 left-0 h-full bg-light;
    animation: stretchin 60s linear infinite;
}
@keyframes stretchin {
    from {
        width: 0%;
        left: 0%
    }
    50% {
        width: 100%;
        left: 0%
    }
    to {
        width: 0%;
        left: 100%;
    }
}

.trigger-warning {
    @apply flex flex-col justify-center;
}
.trigger-warning img {
    @apply inline-block max-h-8 mx-2 -my-2; 
}
.trigger-warning p {
    @apply my-4 text-sm;
}
.trigger-warning p button {
    @apply underline pointer-events-auto underline-offset-4;
}
.trigger-warning p button:hover {
    @apply decoration-2;
}

#loading { height: 10%; }
#loading path {
    stroke-dasharray: 300;
    stroke-dashoffset: 0;
    animation: cycle 3s linear infinite;
}
@keyframes cycle {
    0% {
        stroke-dashoffset: 600;
    }
    50% {
        stroke-dashoffset: 300;
    }
    100% {
      stroke-dashoffset: 0;
    }
}
#loading.hide {
    transition: opacity 1s;
    opacity: 0;
}
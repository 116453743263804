@tailwind base;
@tailwind components;
@tailwind utilities;

.default-share {
    @apply flex absolute left-1/2 w-fit h-full max-h-fit p-8 mx-auto z-10 bottom-0 pointer-events-none;
    transform: translateX(-50%);
}
.default-share.active { @apply pointer-events-auto; }
#share {
    @apply z-20;
}
.default-share.is-open {
    @apply flex;
}
.share-wrap {
    @apply flex gap-20 items-center;
}
.share-icon {@apply transition-all; }

.shareIcon path { transform: translate(0px, 3px); }
.shareIcon #up { transition: transform .5s; }
.shareIcon.up #up {
  transform: translate(0px, -2px);
}